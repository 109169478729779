import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { number } from "prop-types";
import DetailPengajuan from "./TabPengajuan/indexSteps";
import { useDispatch, useSelector } from "react-redux";
import { getAllPengajuan } from "stores/Pengajuan/function";
import { getAllStatusPengajuan } from "stores/Pengajuan/function";
import { Dna } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import "./Pagination.css";

function Pengajuan() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const modalDetailPengajuan = useSelector((state) => state.pengajuanReducer);
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  // console.log(pengajuan.listPengajuan.data, "Dari Store");
  const dispatch = useDispatch();
  const [listPengajuan, setListPengajuan] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [listStatus, setListStatus] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    setPage(0);
    getAllPengajuan(dispatch, {
      status: pilihOpsi,
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const handleDetailsClick = (val) => {
    dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
    dispatch({ type: "SET_DETAIL_PENGAJUAN", data: val });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  React.useEffect(() => {
    if (pengajuan.listPengajuan.data) {
      let tmp = pengajuan.listPengajuan.data.map((val) => {
        return {
          ...val,
          namaCabangPencairan: !val.lokasiPencairan
            ? "-"
            : val.lokasiPencairan.namaCabang,
          action: (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Details Pengajuan</Tooltip>}
            >
              <Button
                className="mr-0"
                // variant="info"
                style={{ marginTop: 5, backgroundColor: "#30B9FB", border: 0 }}
                onClick={() => {
                  handleDetailsClick(val);
                  // console.log(val, "AKAKAKKAKAKAKAKAK");
                }}
              >
                <i class="fas fa-info"></i>
              </Button>
            </OverlayTrigger>
          ),
        };
      });
      setListPengajuan(tmp);
    }
  }, [pengajuan.listPengajuan.data]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  // React.useEffect(() => {
  //   let tmp =
  //     pengajuan.listPengajuan.data &&
  //     pengajuan.listPengajuan.data.map((val) => {
  //       return {
  //         ...val,
  //         namaCabangPencairan: !val.lokasiPencairan
  //           ? "-"
  //           : val.lokasiPencairan.namaCabang,
  //         action: (
  //           <>
  //             {" "}
  //             <Button
  //               className="mr-0"
  //               variant="info"
  //               style={{ marginTop: 5 }}
  //               onClick={() => handleDetailsClick(val)}
  //             >
  //               Details
  //             </Button>
  //           </>
  //         ),
  //       };
  //     });
  //   setListPengajuan(tmp);
  // }, [pengajuan.listPengajuan.data]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: false,
      },
      {
        value: "Menunggu Konfirmasi",
        label: "Menunggu Konfirmasi",
      },
      {
        value: "Diproses",
        label: "Diproses",
      },
      {
        value: "Disetujui",
        label: "Disetujui",
      },
      {
        value: "Revisi",
        label: "Revisi",
      },
      {
        value: "Ditolak",
        label: "Ditolak",
      },
    ];
    setListStatus(tmp);
  }, [pengajuan.listPengajuan.data, page, totalPerHalaman]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Menunggu Konfirmasi":
        return {
          backgroundColor: "#FFF5CD",
          textColor: "#FFAF05",
          fontWeight: "bold",
        };
      case "Diproses":
        return {
          backgroundColor: "#fcceb3",
          textColor: "#fa5d02",
          fontWeight: "bold",
        };
      case "Disetujui":
        return {
          backgroundColor: "#E3FCDB",
          textColor: "#5AC967",
          fontWeight: "bold",
        };
      case "Revisi":
        return {
          backgroundColor: "#FFE2E6",
          textColor: "#FF2B4B",
          fontWeight: "bold",
        };
      case "Ditolak":
        return {
          backgroundColor: "#E10F00",
          textColor: "#FFFFFF",
          fontWeight: "bold",
        };
      default:
        return {};
    }
  };

  // const getStatusStyleSelect = (status) => {
  //   switch (status) {
  //     case "Menunggu Konfirmasi":
  //       return { textColor: "#FFAF05" };
  //     case "Diajukan":
  //       return { textColor: "#1776F1" };
  //     case "Diproses":
  //       return { textColor: "#fa5d02" };
  //     case "Disetujui":
  //       return { textColor: "#5AC967" };
  //     case "Revisi":
  //       return { textColor: "#FF2B4B" };
  //     case "Ditolak":
  //       return { textColor: "#E10F00" };
  //     default:
  //       return {};
  //   }
  // };

  // const customStylesSelect = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: getStatusStyleSelect(state.label).backgroundColor,
  //     color: getStatusStyleSelect(state.data.label).textColor,
  //     padding: "8px",
  //     borderRadius: "4px",
  //   }),
  // };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colPengajuan = [
    {
      name: "No",
      width: "100px",
      center: true,
      // selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      selector: (row, index) => index + pengajuan.listPengajuan.pagingCounter,
    },
    {
      name: "Referensi ID",
      minWidth: "250px",
      center: true,
      selector: (row) => row.id_pinjaman,
    },
    {
      name: "Calon Nasabah",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaLengkap,
    },
    {
      name: "Nominal Permohonan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.stepEmpat.nominalPermohonan);
        return formattedValue;
      },
    },
    {
      name: "Cabang Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaCabangPencairan,
    },
    {
      name: "Sales",
      minWidth: "200px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Tanggal Pengajuan",
      minWidth: "200px",
      center: true,
      selector: (row) => moment(new Date(row.createdAt)).format("DD MMMM YYYY"),
    },
    {
      name: "Status",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusPengajuan,
      cell: (row) => {
        const statusStyle = getStatusStyle(row.statusPengajuan);
        return (
          <div
            style={{
              backgroundColor: statusStyle.backgroundColor,
              fontWeight: statusStyle.fontWeight,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.statusPengajuan}
          </div>
        );
      },
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getAllPengajuan(dispatch, {
      status:
        filterStatus === null || filterStatus.value === ""
          ? ""
          : filterStatus.value,
      cari: "",
      page: page + 1,
      limit: totalPerHalaman,
    })
      .then((response) => {
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [modalDetailPengajuan.modalDetailPengajuan, page, totalPerHalaman]);

  return (
    <>
      {modalDetailPengajuan.modalDetailPengajuan ? (
        <>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Title
                      as="h3"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      List Pengajuan
                    </Card.Title>
                    <Tab.Content>
                      <Card style={{ border: 0, marginTop: 20 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Col md={"4"}>
                            <InputGroup>
                              <Form.Control
                                style={{
                                  paddingLeft: "10px",
                                }}
                                type="text"
                                value={searchText}
                                onChange={handleSearch}
                                placeholder="Reffrence ID/Nasabah"
                                onKeyDown={handleKeyDown}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClearSearch}
                                >
                                  <i class="fas fa-times"></i>
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </Col>

                          <Col md={"3"}>
                            <div>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Status"
                                value={filterStatus}
                                onChange={(selectedOption) => {
                                  setFilterStatus(selectedOption);
                                  setPilihOpsi(selectedOption.value);
                                }}
                                onKeyDown={handleKeyDown}
                                options={[
                                  { value: "", label: "All" },
                                  ...listStatus,
                                ]}
                                placeholder="Pilih Status"
                              />
                            </div>
                          </Col>
                          <Col>
                            <Button
                              // variant="info"
                              style={{
                                fontSize: 16,
                                backgroundColor: "#00BFFF",
                                border: 0,
                                paddingTop: 10,
                                paddingBottom: 7,
                              }}
                              onClick={handleFilterChange}
                            >
                              <i class="fas fa-search"></i>
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Tab.Content>
                  </Card.Header>
                  <Card.Body>
                    {isLoading ? (
                      <div
                        className="loader-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "30vh",
                        }}
                      >
                        <Dna
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="dna-loading"
                          wrapperStyle={{}}
                          wrapperClass="dna-wrapper"
                        />
                        <p>Loading...</p>
                      </div>
                    ) : (
                      <div>
                        <DataTable
                          columns={colPengajuan}
                          data={listPengajuan}
                          // data={listPengajuan.filter(
                          //   (row) =>
                          //     row.id_pinjaman
                          //       .toLowerCase()
                          //       .includes(searchText.toLowerCase()) ||
                          //     row.namaLengkap
                          //       .toLowerCase()
                          //       .includes(searchText.toLowerCase())
                          // )}
                          customStyles={customStyles}
                          paginationPerPage={totalPerHalaman}
                          progressPending={isLoading}
                        />
                        <div style={{ marginTop: 35 }}>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              <Row style={{ alignItems: "center" }}>
                                <div
                                  style={{
                                    paddingRight: 0,
                                    marginLeft: 20,
                                    marginRight: 10,
                                  }}
                                >
                                  <label style={{ fontWeight: "bold" }}>
                                    Rows per page
                                  </label>
                                </div>
                                <Col sm="3" style={{ paddingLeft: 0 }}>
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="Pilih Client"
                                    value={options.find(
                                      (option) =>
                                        option.value === totalPerHalaman
                                    )}
                                    onChange={handlePageSizeChange}
                                    options={options}
                                    placeholder="Rows per page"
                                  />
                                </Col>
                              </Row>
                            </Col>

                            <Col>
                              <ReactPaginate
                                forcePage={page}
                                containerClassName="paginations justify-content-end"
                                previousClassName="pagess-items-containers"
                                previousLinkClassName="pages-linkss-labels"
                                nextClassName="pagess-items-containers"
                                nextLinkClassName="pages-linkss-labels"
                                breakClassName="pagess-items-containers"
                                pageCount={pengajuan.listPengajuan.totalPages}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageLinkClassName="pages-linkss-labels"
                                pageClassName="pagess-items-containers"
                                activeClassName="actives"
                                activeLinkClassName="activess"
                                nextLabel=" > "
                                previousLabel=" < "
                                initialPage={page}
                                disableInitialCallback={true}
                                onPageChange={(val) => {
                                  setPage(val.selected);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <DetailPengajuan />
        </>
      )}
    </>
  );
}

export default Pengajuan;
