import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import { Dna, Watch } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { getAllKunjunganByLogin } from "stores/kunjungan/function";
import { updateApprovalKunjungan } from "stores";
import GoogleMapReact from "google-map-react";
import { exportPdfKunjungan } from "stores";
import { editFotoKunjungan } from "stores";
import { ajukanRevisiKunjungan } from "stores";

function ListKunjungan() {
  const dispatch = useDispatch();
  const kunjungan = useSelector((state) => state.kunjunganReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listDataKunjungan, setListDataKunjungan] = React.useState([]);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [modalRevisi, setModalRevisi] = React.useState(false);
  const [keterangan, setKeterangan] = React.useState("");
  const [namaNasabah, setNamaNasabah] = React.useState("");
  const [modalEditFoto, setModalEditFoto] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [buktiKunjungan, setBuktiKunjungan] = React.useState("");
  const [tampilFotoKunjungan, setTampilFotoKunjungan] = React.useState("");
  const [kirimFotoKunjungan, setKirimFotoKunjungan] = React.useState("");
  const [urlFoto, setUrlFoto] = React.useState("");
  const [idKunjungan, setIdKunjungan] = React.useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [filterStatus, setFilterStatus] = React.useState(null);
  const [pilihOpsi, setPilihOpsi] = React.useState("");
  const [listStatus, setListStatus] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [showUploadForm, setShowUploadForm] = React.useState(false);
  const [modalExport, setModalExport] = React.useState(false);
  const [tglAwal, setTglAwal] = React.useState("");
  const [tglAkhir, setTglAkhir] = React.useState("");
  const [selectedKeterangan, setSelectedKeterangan] = React.useState(null);
  const [modalKeteranganVisible, setModalKeteranganVisible] =
    React.useState(false);

  const handleKeteranganClick = (keterangan) => {
    setSelectedKeterangan(keterangan);
    setModalKeteranganVisible(true);
  };

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleFilterChange = () => {
    setIsLoading(true);
    getAllKunjunganByLogin(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setIsLoading(false); // Setelah selesai memuat data
      })
      .catch((error) => {
        setIsLoading(false); // Jika terjadi error
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  const submitAjukanRevisiKunjungan = () => {
    if (keterangan === "") {
      Swal.fire({
        title: "Keterangan Wajib Diisi",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      title: "Yakin ingin mengajukan revisi?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ajukan",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        ajukanRevisiKunjungan({
          idKunjungan: idKunjungan,
          keterangan: keterangan,
        })
          .then((response) => {
            if (response.data.status === 200) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1000,
              });
              handleCloseModal();
              setModalRevisi(false);
              getAllKunjunganByLogin(dispatch, {
                page: page + 1,
                limit: totalPerHalaman,
                cari: searchText,
              });
            } else if (response.data.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            console.log("ERRORRRR", error);
            Swal.fire({
              icon: "error",
              title: "Terjadi kesalahan!",
              text: "Silakan coba lagi nanti.",
            });
          });
      }
    });
  };

  // const submitAjukanRevisiKunjungan = () => {
  //   if (keterangan == "") {
  //     Swal.fire({
  //       title: "Keterangan Wajib Diisi",
  //       icon: "warning",
  //     });
  //     return;
  //   }
  //   ajukanRevisiKunjungan({
  //     idKunjungan: idKunjungan,
  //     keterangan: keterangan,
  //   })
  //     .then((response) => {
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: response.data.message,
  //           showConfirmButton: false,
  //           timer: 1000,
  //         });
  //         handleCloseModal();
  //         setModalRevisi(false);
  //         getAllKunjunganByLogin(dispatch, {
  //           page: page + 1,
  //           limit: totalPerHalaman,
  //           cari: searchText,
  //         });
  //       } else if (response.data.status === 400) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: response.data.message,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("ERRORRRR", error);
  //     });
  // };

  function submitEditFoto(e) {
    e.preventDefault();
    if (kirimFotoKunjungan === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Foto tidak boleh kosong !",
      });
      return;
    }
    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin ingin menyimpan perubahan?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        let dataToSend = new FormData();
        dataToSend.append("idKunjungan", idKunjungan);
        dataToSend.append("urlFoto", urlFoto);
        dataToSend.append("buktiKunjungan", kirimFotoKunjungan);

        editFotoKunjungan(dataToSend)
          .then((response) => {
            // console.log(response, "HHH");
            if (response.status === 200) {
              Swal.close();
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              setModalEditFoto(false);

              // window.location.reload();
            } else if (response.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  React.useEffect(() => {
    let tmp =
      kunjungan.dataKunjungan.docs &&
      kunjungan.dataKunjungan.docs.map((val) => {
        const isDisabled =
          val.statusRevisi === true ||
          (auth.role === "client" &&
            (!val.approvalSupervisor || val.approvalKacap === true)) ||
          (auth.role === "supervisor" && val.approvalSupervisor) ||
          val.keputusan === "Ditolak";
        const isRevisiDisabled =
          (auth.role === "supervisor" &&
            (val.statusRevisi === true || val.approvalSupervisor === true)) ||
          (auth.role === "client" &&
            auth.jabatan === "korcab" &&
            auth.nopeg === "rizalrompis" &&
            auth.nopeg === "susansampangai" &&
            val.approvalSupervisor === false) ||
          val.statusRevisi === true;
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Detail</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#659EC7",
                    border: 0,
                  }}
                  onClick={() => {
                    setModalDetail(!modalDetail);
                    setBuktiKunjungan(val.buktiKunjungan);
                    setLatitude(parseFloat(val.lat));
                    setLongitude(parseFloat(val.long));
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>
              {(auth.role === "client" && auth.jabatan === "korcab") ||
              auth.role === "super admin" ||
              auth.role === "supervisor" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Approval Kunjungan Nasabah</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    disabled={isDisabled}
                    style={{
                      marginTop: 5,
                      backgroundColor: isDisabled ? "grey" : "#2CC4A0",
                      border: 0,
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                    onClick={async () => {
                      const loadingSwal = Swal.fire({
                        title: "Loading...",
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                          Swal.showLoading();
                        },
                      });

                      try {
                        const result = await Swal.fire({
                          title: "Approve Kunjungan",
                          text: `Apakah anda yakin menyetujui ${val.calonNasabah.namaNasabah} tersebut ?`,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Ya",
                        });

                        if (result.isConfirmed) {
                          const response = await updateApprovalKunjungan({
                            idKunjungan: val._id,
                          });
                          loadingSwal.close();

                          Swal.fire({
                            title: "Sukses",
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 2000,
                          });

                          getAllKunjunganByLogin(dispatch, {
                            page: page + 1,
                            limit: totalPerHalaman,
                            cari: searchText,
                          });
                        }
                      } catch (error) {
                        loadingSwal.close();
                        if (error.response && error.response.status === 400) {
                          Swal.fire({
                            title: "Error",
                            text: error.response.data.message,
                            icon: "error",
                            showConfirmButton: true,
                          });
                        }
                      }
                    }}
                  >
                    <i className="fas fa-check"></i>
                  </Button>
                </OverlayTrigger>
              ) : null}
              {(auth.role === "client" && auth.jabatan === "korcab") ||
              auth.nopeg === "rizalrompis" ||
              auth.nopeg === "susansampangai" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Edit Foto</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    // variant="info"
                    style={{
                      marginTop: 5,
                      backgroundColor: "#013E62",
                      border: 0,
                    }}
                    onClick={() => {
                      setModalEditFoto(!modalEditFoto);
                      setIdKunjungan(val._id);
                      setUrlFoto(val.buktiKunjungan);
                      setTampilFotoKunjungan(val.buktiKunjungan);
                    }}
                  >
                    <i class="fas fa-images"></i>
                  </Button>
                </OverlayTrigger>
              ) : null}
              {(auth.role === "client" && auth.jabatan === "korcab") ||
              auth.nopeg === "rizalrompis" ||
              auth.nopeg === "susansampangai" ||
              auth.role === "supervisor" ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Revisi Kunjungan</Tooltip>}
                >
                  <Button
                    className="ml-2"
                    // variant="info"
                    disabled={isRevisiDisabled}
                    style={{
                      marginTop: 5,
                      backgroundColor: isRevisiDisabled ? "grey" : "#768498",
                      border: 0,
                      cursor: isRevisiDisabled ? "not-allowed" : "pointer",
                    }}
                    onClick={() => {
                      // console.log(val, "LLLL");
                      setModalRevisi(!modalRevisi);
                      setNamaNasabah(val.calonNasabah.namaNasabah);
                      setIdKunjungan(val._id);
                    }}
                  >
                    <img
                      alt="..."
                      src={require("assets/img/revision.png").default}
                      style={{ width: "21px", height: "21px" }}
                    />
                  </Button>
                </OverlayTrigger>
              ) : null}
            </>
          ),
        };
      });
    setListDataKunjungan(tmp);
  }, [kunjungan.dataKunjungan, auth]);

  const handleCloseModal = () => {
    setShowUploadForm(false);
    setKeterangan("");
  };

  const AnyReactComponent = ({ text }) => (
    <div style={{ color: "red", fontWeight: "bold" }}>
      <i className="fas fa-map-marker-alt"></i> {text}
    </div>
  );

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilterChange();
    }
  };

  React.useEffect(() => {
    let tmp = [
      {
        value: "Batal",
        label: "Batal",
      },

      {
        value: "Tunda",
        label: "Tunda",
      },
    ];
    setListStatus(tmp);
  }, [kunjungan.dataKunjungan, page, totalPerHalaman]);

  const defaultProps = {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 11,
  };

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const KeteranganModal = () => (
    <Modal
      show={modalKeteranganVisible}
      onHide={() => setModalKeteranganVisible(false)}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Title
        style={{ textAlign: "center", fontWeight: "bolder", margin: 0 }}
      >
        Keterangan Lengkap
        <hr></hr>
      </Modal.Title>
      {/* <Modal.Body>{selectedKeterangan}</Modal.Body> */}
      <Card.Body>{selectedKeterangan}</Card.Body>
      {/* <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setModalKeteranganVisible(false)}
        >
          Tutup
        </Button>
      </Modal.Footer> */}
    </Modal>
  );

  const getHasil = (hasilNasabah) => {
    switch (hasilNasabah) {
      case "Batal":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "Pinjam":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      case "Perhitungan Minus":
        return {
          fontWeight: "bold",
          backgroundColor: "#E3EEF9",
          textColor: "#1569C7",
        };
      case "-":
        return {
          fontWeight: "bold",

          textColor: "#000000",
        };
      default:
        return {};
    }
  };
  const getStatusRevisi = (revisi) => {
    switch (revisi) {
      case true:
        return {
          fontWeight: "bold",
          backgroundColor: "#FBECEC",
          textColor: "#DB5856",
        };
      case false:
        return {
          fontWeight: "bold",
          // backgroundColor: "#E6E6FF",
          // textColor: "#0000FF",
        };

      default:
        return {};
    }
  };

  const getKeputusan = (keputusan) => {
    switch (keputusan) {
      case "Tunda":
        return {
          fontWeight: "bold",
          backgroundColor: "#FFE6CC",
          textColor: "#FF7F00",
        };
      case "Batal":
        return {
          fontWeight: "bold",
          backgroundColor: "#F7DFE9",
          textColor: "#B0054B",
        };
      case "Disetujui":
        return {
          fontWeight: "bold",
          backgroundColor: "#E4F5C9",
          textColor: "#87CB16",
        };
      case "Ditolak":
        return {
          fontWeight: "bold",
          backgroundColor: "#FF0000",
          textColor: "#FFE6E6",
        };
      default:
        return {};
    }
  };

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colKunjungan = [
    {
      name: "No",
      width: "100px",
      center: true,
      // selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
      selector: (row, index) => index + kunjungan.dataKunjungan.pagingCounter,
    },
    {
      name: "Nama Nasabah",
      minWidth: "250px",
      center: true,
      selector: (row) =>
        !row.calonNasabah ? "-" : row.calonNasabah.namaNasabah,
    },
    {
      name: "Tgl Kunjungan",
      minWidth: "150px",
      center: true,
      selector: (row) => moment(row.tglKunjungan).format("DD MMMM YYYY"),
    },
    {
      name: "Plafond",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.plafond);
        return formattedValue;
      },
    },
    {
      name: "Nett",
      minWidth: "180px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.nett);
        return formattedValue;
      },
    },
    {
      name: "Keputusan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.keputusan,
      cell: (row) => {
        const statusStyle = getKeputusan(row.keputusan);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {row.keputusan}
          </div>
        );
      },
    },
    {
      name: "Hasil",
      minWidth: "200px",
      center: true,
      selector: (row) => row.hasil,
      cell: (row) => {
        const hasil = row.hasil === "" ? "-" : row.hasil;
        const statusStyle = getHasil(hasil);
        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {hasil}
          </div>
        );
      },
    },
    {
      name: "Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) => row.cabang.namaCabang,
    },
    {
      name: "Koordinator Cabang",
      minWidth: "250px",
      center: true,
      selector: (row) => row.korcab.nama,
    },
    {
      name: "Marketing",
      minWidth: "250px",
      center: true,
      selector: (row) => row.marketing.nama,
    },
    {
      name: "Supervisor",
      minWidth: "250px",
      center: true,
      selector: (row) => row.supervisor.nama,
    },
    {
      name: "Area Manager",
      minWidth: "250px",
      center: true,
      selector: (row) => row.areaManager.nama,
    },
    {
      name: "Status Revisi Kunjungan",
      minWidth: "200px",
      center: true,
      selector: (row) => row.statusRevisi,
      cell: (row) => {
        const statusStyle = getStatusRevisi(row.statusRevisi);
        const tampilanLabel =
          row.statusRevisi === true ? "Sedang Direvisi" : "-";

        return (
          <div
            style={{
              fontWeight: statusStyle.fontWeight,
              backgroundColor: statusStyle.backgroundColor,
              color: statusStyle.textColor,
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {tampilanLabel}
          </div>
        );
      },
    },
    {
      name: "Revisi Dari",
      minWidth: "260px",
      center: true,
      selector: (row) =>
        row.revisiDari === "" ? "-" : !row.revisiDari ? "-" : row.revisiDari,
    },
    {
      name: "BAPLOAN",
      minWidth: "260px",
      center: true,
      selector: (row) =>
        row.bapLoan === "" ? "-" : !row.bapLoan ? "-" : row.bapLoan,
    },
    {
      name: "Keterangan",
      minWidth: "300px",
      center: true,
      selector: (row) => (row.keterangan === "" ? "-" : row.keterangan),
      cell: (row) => {
        const maxLength = 20;
        const keterangan = row.keterangan || "-";
        const isLongText = keterangan.length > maxLength;
        const truncatedKeterangan = isLongText
          ? `${keterangan.substring(0, maxLength)}...`
          : keterangan;

        return (
          <>
            <div>{truncatedKeterangan}</div>
            {isLongText && (
              <div
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => handleKeteranganClick(keterangan)}
              >
                Selengkapnya
              </div>
            )}
          </>
        );
      },
    },
    // {
    //   name: "Keterangan",
    //   minWidth: "300px",
    //   center: true,
    //   selector: (row) => (row.keterangan === "" ? "-" : row.keterangan),
    // },
    {
      name: "Aksi",
      minWidth: "300px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    setIsLoading(true);
    getAllKunjunganByLogin(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
      cari: searchText,
    })
      .then((response) => {
        setIsLoading(false); // Setelah selesai memuat data
      })
      .catch((error) => {
        setIsLoading(false); // Jika terjadi error
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  }, [page, totalPerHalaman]);
  return (
    <>
      <Modal
        size="lg"
        show={modalExport}
        onHide={() => {
          setModalExport(false);
          handleExportPengajuan();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        Export Kunjungan PDF
                        <hr></hr>
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            Tanggal Awal
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setTglAwal(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 12,
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            Tanggal Akhir
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setTglAkhir(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
                <Button
                  className="btn-fill pull-right"
                  type="submit"
                  style={{
                    // marginLeft: 20,
                    backgroundColor: "#013E62",
                    fontWeight: "bold",
                    border: 0,
                  }}
                  // onClick={submitExport}
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalRevisi}
        onHide={() => {
          setModalRevisi(false);
          handleCloseModal();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
            <Card className="stacked-form" style={{ border: 0 }}>
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Revisi Nasabah : {namaNasabah}
                  <hr></hr>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Col sm="12">
                  <Form.Group>
                    <label
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "grey",
                        textTransform: "none",
                      }}
                    >
                      Keterangan
                    </label>
                    <Form.Control
                      as="textarea"
                      onChange={(e) => {
                        setKeterangan(e.target.value);
                      }}
                      rows={4}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Button
                  type="submit"
                  // variant="info"
                  style={{
                    marginTop: 10,
                    marginBottom: 20,
                    backgroundColor: "#DB5856",
                    fontWeight: "bold",
                    border: 0,
                  }}
                  onClick={submitAjukanRevisiKunjungan}
                >
                  Submit
                </Button>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalEditFoto}
        onHide={() => {
          setModalEditFoto(false);
          handleCloseModal();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card style={{ border: 0 }}>
                  <Card.Header>
                    <Row style={{ alignItems: "center" }}>
                      <Col>
                        <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                          Edit Foto Kunjungan
                        </Card.Title>
                      </Col>
                      <Col style={{ textAlign: "right" }}>
                        <Button
                          variant="primary"
                          onClick={() => setShowUploadForm((prev) => !prev)} // Toggle form upload
                          style={{
                            backgroundColor: "#013E62",
                            border: "none",
                            fontWeight: "bold",
                          }}
                        >
                          Edit Foto
                        </Button>
                      </Col>
                    </Row>
                  </Card.Header>
                  <hr />
                  <Card.Body
                    style={{
                      height: "100%",
                    }}
                  >
                    {showUploadForm && (
                      <>
                        <Row>
                          <Col>
                            <li>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  color: "black",
                                  textTransform: "none",
                                }}
                              >
                                Pilih Foto
                              </label>
                            </li>
                          </Col>

                          <Col md="12">
                            <Form.Control
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => {
                                setKirimFotoKunjungan(e.target.files[0]);
                                // setSizeFile(e.target.files[0].size / 1000000);
                              }}
                            ></Form.Control>
                            <p
                              className="text-muted"
                              style={{ fontSize: 13, marginTop: 2 }}
                            >
                              .png, .jpg, .jpeg
                            </p>
                          </Col>
                        </Row>
                        <hr></hr>
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        marginTop: 50,
                      }}
                    >
                      <Card.Title
                        as="h4"
                        style={{ fontWeight: "bold", marginBottom: 20 }}
                      >
                        Foto Kunjungan
                      </Card.Title>
                      {tampilFotoKunjungan ? (
                        <img
                          src={
                            tampilFotoKunjungan +
                            "?test=" +
                            new Date().getTime().toString()
                          }
                          alt="Bukti Kunjungan"
                          style={{ maxWidth: "60%", maxHeight: "50%" }}
                        />
                      ) : (
                        <>
                          <div style={{ marginBottom: "1rem" }}>
                            <div
                              style={{
                                backgroundColor: "#E0D5FA",
                                color: "#703BE7",
                                borderRadius: "8px",
                                padding: "10px 15px",
                                boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Maaf, masih belum ada bukti Kunjungan untuk saat
                              ini..
                            </div>
                          </div>
                          <img
                            src={require("assets/img/sorry.jpg").default}
                            alt="Sorry"
                            style={{ maxWidth: "50%", borderRadius: "5px" }}
                          />
                        </>
                      )}
                    </div>

                    {/* Form untuk upload foto */}
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Card.Footer style={{ backgroundColor: "white" }}>
          <Button
            className="btn-fill"
            type="submit"
            // variant="info"
            style={{
              backgroundColor: "#013E62",
              border: 0,
              fontWeight: "bold",
            }}
            onClick={submitEditFoto}
          >
            Submit
          </Button>
        </Card.Footer>
      </Modal>

      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
            <Card className="stacked-form" style={{ border: 0 }}>
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Detail Kunjungan
                  <hr></hr>
                </Card.Title>
              </Card.Header>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {buktiKunjungan ? (
                  <img
                    src={
                      buktiKunjungan +
                      "?test=" +
                      new Date().getTime().toString()
                    }
                    alt="Bukti Kunjungan"
                    style={{ maxWidth: "60%", maxHeight: "50%" }}
                  />
                ) : (
                  <>
                    <div style={{ marginBottom: "1rem" }}>
                      <div
                        style={{
                          backgroundColor: "#E0D5FA",
                          color: "#703BE7",
                          borderRadius: "8px",
                          padding: "10px 15px",
                          boxShadow: "0 3px 8px rgba(0, 1, 0, 0.3)",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Maaf, masih belum ada bukti Kunjungan untuk saat ini..
                      </div>
                    </div>
                    <img
                      src={require("assets/img/sorry.jpg").default}
                      alt="Sorry"
                      style={{ maxWidth: "50%", borderRadius: "5px" }}
                    />
                  </>
                )}
              </Card.Body>
              <Card.Title
                as="h4"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Map
                <hr></hr>
              </Card.Title>
              <div style={{ height: "50vh", width: "58%", margin: "auto" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAXcaz5mPbGbZs4rrFVHLTANG0NLoBEp2I",
                  }}
                  center={{
                    lat: latitude || defaultProps.center.lat,
                    lng: longitude || defaultProps.center.lng,
                  }}
                  defaultZoom={defaultProps.zoom}
                >
                  {latitude !== null && longitude !== null && (
                    <AnyReactComponent
                      lat={latitude}
                      lng={longitude}
                      // text="My Marker"
                    />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </div>
        </Col>
      </Modal>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  List Kunjungan
                  <hr></hr>
                </Card.Title>
                <Tab.Content>
                  <Card style={{ border: 0, marginTop: 20 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Col md={"4"}>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Nama Nasabah"
                            onKeyDown={handleKeyDown}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              style={{ cursor: "pointer" }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col>
                        <Button
                          // variant="info"
                          style={{
                            fontSize: 16,
                            backgroundColor: "#F75D59",
                            border: 0,
                            paddingTop: 10,
                            paddingBottom: 7,
                          }}
                          onClick={handleFilterChange}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                      </Col>
                      {/* <Button
                        className="btn-wd mr-3"
                        onClick={() => {
                          setModalExport(true);
                        }}
                        style={{
                          // marginLeft: 20,
                          backgroundColor: "#013E62",
                          fontWeight: "bold",
                          border: 0,
                        }}
                      >
                        <img
                          alt="..."
                          src={require("assets/img/pdf3.png").default}
                          style={{
                            width: "27px",
                            height: "25px",
                            marginRight: 10,
                          }}
                        />
                        Export Kunjungan PDF
                      </Button> */}
                    </Row>
                  </Card>
                </Tab.Content>
              </Card.Header>
              <Card.Body>
                {isLoading ? (
                  <div
                    className="loader-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "30vh",
                    }}
                  >
                    <Watch
                      visible={true}
                      height="80"
                      width="80"
                      radius="30"
                      color="#F75D59"
                      ariaLabel="watch-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div>
                    <DataTable
                      columns={colKunjungan}
                      data={listDataKunjungan}
                      customStyles={customStyles}
                      paginationPerPage={totalPerHalaman}
                      // progressPending={isLoading}
                    />
                    {modalKeteranganVisible && <KeteranganModal />}
                    <div style={{ marginTop: 35 }}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Row style={{ alignItems: "center" }}>
                            <div
                              style={{
                                paddingRight: 0,
                                marginLeft: 20,
                                marginRight: 10,
                              }}
                            >
                              <label style={{ fontWeight: "bold" }}>
                                Rows per page
                              </label>
                            </div>
                            <Col sm="3" style={{ paddingLeft: 0 }}>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={options.find(
                                  (option) => option.value === totalPerHalaman
                                )}
                                onChange={handlePageSizeChange}
                                options={options}
                                placeholder="Rows per page"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <ReactPaginate
                            forcePage={page}
                            containerClassName="paginations justify-content-end"
                            previousClassName="pages-itemss-containers"
                            previousLinkClassName="pages-links-labelss"
                            nextClassName="pages-itemss-containers"
                            nextLinkClassName="pages-links-labelss"
                            breakClassName="pages-itemss-containers"
                            pageCount={kunjungan.dataKunjungan.totalPages}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageLinkClassName="pages-links-labelss"
                            pageClassName="pages-itemss-containers"
                            activeClassName="activees"
                            activeLinkClassName="aactivess"
                            nextLabel=" > "
                            previousLabel=" < "
                            initialPage={page}
                            disableInitialCallback={true}
                            onPageChange={(val) => {
                              setPage(val.selected);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ListKunjungan;
